import React from 'react';
import { Link } from 'react-router-dom';
import { Container, List, ListItems } from 'screens/SiteMap/styles';
import ModalReplyFromDashboard from 'screens/ModalReplyFromDashboard';
import ModalGroupCancel from 'screens/ModalGroupCancel';
import ModalGroupBookingCancel from 'screens/ModalGroupBookingCancel';
import ModalBookingCancelChangeCard from 'screens/ModalBookingCancelChangeCard';
import ModalBookingNotComplete from 'screens/ModalBookingNotComplete';
import ModalRemoveSecondaryGuest from 'screens/ModalRemoveSecondaryGuest';
import ModalKeyPrinting from 'screens/ModalKeyPrinting';
import ModalCheckout from 'screens/ModalCheckOut';
import ModalViewImage from 'screens/ModalViewImage';
import ModalStaffModifyAccess from 'screens/ModalStaffModifyAccess';
import ModalAddGenerateKey from 'screens/ModalGenerateKey';
import ModalAddCharge from 'screens/ModalAddCharge';
import ModalAddDiscount from 'screens/ModalAddDiscount';
import ModalChangeRoom from 'screens/ModalChangeRoom';
import ModalAddCreditCard from 'screens/ModalAddCreditCard';
import ModalOverridePrice from 'screens/ModalOverridePrice';
import ModalRefund from 'screens/ModalRefund';
import ModalAddGuestToBooking from 'screens/ModalAddExistingToGroup';

const SiteMap = () => {
  return (
    <div style={{backgroundColor: 'black'}}>
      <Container>
        <List>
          <ListItems>NAVIGATOR</ListItems>
          <ListItems>
            <Link to="/">HOME</Link>
          </ListItems>
          <ListItems>
            <Link to="/bookings">BOOKINGS</Link>
          </ListItems>
          <ListItems>
            <Link to="/create-booking">CREATE BOOKING</Link>
          </ListItems>
          <ListItems>
            <Link to="/rooms">ROOMS</Link>
          </ListItems>
          <ListItems>
            <Link to="/staff">STAFF</Link>
          </ListItems>
        </List>
        <List>
          <ListItems>REPORT PAGES</ListItems>
          <ListItems>
            <Link to="/occupancy-report">OCCUPANCY REPORT</Link>
          </ListItems>
          <ListItems>
            <Link to="/revenue-report">REVENUE REPORT</Link>
          </ListItems>
          <ListItems>            
            <Link to="/daily-reconciliation">DAILY RECONCILIATION</Link>          
          </ListItems>
          <ListItems>
            <Link to="/bookings-report">BOOKINGS REPORT</Link>
          </ListItems>
          <ListItems>
            <Link to="/room-night-report">ROOM NIGHT REPORT</Link>
          </ListItems>
        </List>
        <List>
          <ListItems>GENERAL PAGES</ListItems>
          <ListItems>
            <Link to="/login">LOGIN</Link>
          </ListItems>
          <ListItems>
            <Link to="/site-map">SITE MAP</Link>
          </ListItems>
        </List>
        <List>
          <ListItems>BOOKING PAGES</ListItems>
          <ListItems>
            <Link to="/booking-group/e27a268e-e1c9-4ccb-9783-8e37dc9eb837">BOOKING - GROUP</Link>
          </ListItems>
          <ListItems>
            <Link to="/booking-single/f699f02b-49ed-4af1-9a7d-fef39a066259">BOOKING - SINGLE</Link>
          </ListItems>
          <ListItems>
            <Link to="/booking-cancelled/9bbbbc61-e4d6-4a58-b33f-b492636b405b">BOOKING - CANCELLED</Link>
          </ListItems>
          <ListItems>
            <Link to="/review-photos/f699f02b-49ed-4af1-9a7d-fef39a066259">REVIEW - PHOTOS</Link>
          </ListItems>
          <ListItems>
            <Link to="/guest/6d065fa7-d9bd-4a5a-a7ea-7d01ee78e05a">GUEST</Link>
          </ListItems>
          <ListItems>
            <Link to="/staff/5293b5ef-51de-4150-bc23-08aa79bf4435">STAFF</Link>
          </ListItems>
        </List>
        <List>
          <ListItems>MODAL</ListItems>
          <ListItems>
            <ModalReplyFromDashboard />
          </ListItems>
          <ListItems>
            <ModalGroupCancel />
          </ListItems>
          <ListItems>
            <ModalGroupBookingCancel />
          </ListItems>
          <ListItems>
            <ModalBookingCancelChangeCard />
          </ListItems>
          <ListItems>
            <ModalBookingNotComplete />
          </ListItems>
          <ListItems>
            <ModalRemoveSecondaryGuest />
          </ListItems>
          <ListItems>
            <ModalKeyPrinting />
          </ListItems>
          <ListItems>
            <ModalCheckout />
          </ListItems>
          <ListItems>
            <ModalViewImage />
          </ListItems>
          <ListItems>
            <ModalStaffModifyAccess />
          </ListItems>
          <ListItems>
            <ModalAddGenerateKey />
          </ListItems>
          <ListItems>
            <ModalAddCharge />
          </ListItems>
          <ListItems>
            <ModalAddDiscount />
          </ListItems>
          <ListItems>
            <ModalChangeRoom />
          </ListItems>
          <ListItems>
            <ModalAddCreditCard />
          </ListItems>
          <ListItems>
            <ModalAddGuestToBooking />
          </ListItems>
          <ListItems>
            <ModalOverridePrice />
          </ListItems>
          <ListItems>
            <ModalRefund />
          </ListItems>
        </List>
      </Container>
    </div>
  );
}

export default SiteMap;
