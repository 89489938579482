import React from 'react';
import ModalAddCreditCard from 'screens/ModalAddCreditCard';
import { Container } from './styles';

const AddNewCreditCard = ({ tenantId }) => (
  <Container>
    <div>ADD A CREDIT CARD</div>
    <ModalAddCreditCard
      buttonLabel={'Add New Card'}
      buttonStyle={`
        width: 113px;

        @media only screen and (min-width: 767px) and (max-width: 1200px) {
          width: 164px;
        }
      
        @media only screen and (max-width: 767px) {
          width: 143px;
        }
      `}
      tenantId={tenantId}
    />
  </Container>
);

export default AddNewCreditCard;
