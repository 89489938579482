import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  height: 100%;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  & > div {
    color: ${colors.white1};
    font-size: 22px;
    font-weight: 600;
    margin: 15px 0 25px 0;
  }
`;
