import styled from 'styled-components';
import colors from 'constants/colors';

export const MessagesContainer = styled.div`
  position: fixed;
  top: 50px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.black5};
  z-index: 1000;
  height: calc(100vh - 50px);

  @media only screen and (min-width: 1201px){
    display: ${({ mobile }) => (mobile? 'none': 'flex')};
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: calc(100vh - 110px);
    display: ${(props) => (props.open? 'flex': 'none')};
  }

  @media only screen and (max-width: 767px) {
    height: calc(100vh - 95px);
    display: ${(props) => (props.open? 'flex': 'none')};
  }
`;

export const Content = styled.div`
  background-color: ${colors.black5};
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  padding: 15px;
  background-color: ${colors.black5};
  letter-spacing: 0.5px;
  height: 100%;

  .type {
    font-size: 16px;
    font-weight: bold;
    color: ${colors.grey12};
  }
`;