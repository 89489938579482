import styled from 'styled-components';
import colors from 'constants/colors';

export const GroupTotalContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  .totalContainer {
    height: 32px;
    line-height: 32px;
    position: relative;
    padding: 0px 10px;
    background-color: ${colors.black5};
    margin-bottom: 5px;
    
    .value {
      font-size: 14px;
      font-weight: 600;
      margin-left: 20px;
      letter-spacing: 0.5px;
      float: right;
    }

    .subtotal {
      color: ${colors.grey5};
      font-size: 10px;
      float: right;
    }

    .total {
      font-size: 10px;
      font-weight: bold;
      float: right;
    }

    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      .value {
        font-size: 15px;
      }

      .total {
        font-size: 11px;
      }
    }

    @media only screen and (max-width: 767px) {
      .value {
        font-size: 13px;
      }

      .total {
        font-size: 11px;
      }
    }
  }
`;
