import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Input from 'components/Input';
import Search from 'assets/svg/Search.svg';
import Button from 'components/Button';
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import CircularProgress from '@mui/material/CircularProgress';
import { Head, Container, StyledList, StyledItem, Footer } from './styles';
import colors from 'constants/colors';
import { CREATE_GROUP_BOOKING } from 'graphql/service';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const sleep = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const ModalAddExistingToGroup = ({hidden, buttonStyle, groupId, refetch}) => {

  const [ isOpen, setIsOpen ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ keyword, setKeyword ] = useState('')
  const [ options, setOptions ] = useState([])

  // const [createGroupBooking] = useMutation(CREATE_GROUP_BOOKING, {
  //   onCompleted: refetch,
  //   onError: (error) => {
  //     let content = error.message
  //     if(error.message.includes('Validation')) {
  //         content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
  //     }
      
  //     message.error({
  //       content,
  //       duration: getMessageTimeOut(content),
  //       onClick: () => {
  //         message.destroy()
  //       }
  //     })
  //   }
  // })

  const inputHandler = async (keyword) => {

    setLoading(true)
    setIsActive(true)
    setKeyword(keyword)
    //convert input text to lower case
    const lowerCase = keyword.toLowerCase();

    const result = await sleep(500); 

    if (!result) {

      const optionArr = results.map((result, index) => {
        const newResult = {...result}
        const matches = match(result.info, lowerCase, {insideWords: true, findAllOccurrences: true, requireMatchAll: true});
        const parts = parse(result.info, matches);
        newResult.info = parts
        return newResult
      })

      setOptions([...optionArr]);
    }
    setLoading(false)
  }

  const guestArray = options.map((item, index)=> {

    let string = ''
    const guest = item.info.map((str, index) => {

      string += str.text
      return (
        <span
          key={index}
          className={str.highlight? 'highLight': null}
        >
          {str.text}
        </span>
      )
    })
    return (
      <StyledItem
        key={index}
        onClick={()=>{
          setKeyword(string)
          setIsActive(false)
        }}
      >
        {guest}
      </StyledItem>
    )
  })

  guestArray.length=3

  const handleAddGuest = () => {
    // createGroupBooking({
    //   variables: {
    //     input: {
    //       booking: {
    //         groupId,
    //         checkInDate: moment().toDate(),
    //         checkOutDate: moment().add(1, 'days').toDate(),
    //         tenantId
    //       }
    //     }
    //   },
    // })
    setKeyword('')
  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Add existing to group"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 646px;
        .modal-content{
          height: 340px;          
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: 10px;
          }              
          .modal-body {
            position: relative;
            top: -18px;
            height: 100%;
          }
        }
        top: 69px;
      `}
    >
      <Head>Add Existing Guest</Head>
      <Container>
        <Input
          title="SEARCH GUEST"
          value={keyword}
          onChange={(e) => inputHandler(e.target.value)}  
          additionalStyle={`
            width: 100%;
            input {
              background-image: url(${Search});
              background-color: ${colors.black5};
              background-repeat: no-repeat;
              background-position: 100% 50%;
            }
          `}
        />
        {
          loading ? 
            <CircularProgress
              style={{position: 'absolute',top: '81px', right: '440px'}}
              color="success"
              thickness={10}
              size={20}
            /> :
            isActive ?
            <StyledList>
              {guestArray}
            </StyledList>
            :null
        }
      </Container>
      <Footer>
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleAddGuest}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalAddExistingToGroup;
// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const results = [
  { type: 'The Shawshank Redemption', info: '1994' },
  { type: 'The Godfather', info: '1972' },
  { type: 'The Godfather: Part II', info: '1974' },
  { type: 'The Dark Knight', info: '2008' },
  { type: '12 Angry Men', info: '1957' },
  { type: "Schindler's List", info: '1993' },
  { type: 'Pulp Fiction', info: '1994' }
];