import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MapRow = styled.div`
  position: relative;
  top: 20px;
  display: flex;
  justify-content: space-between;
  row-gap: 30px;
  margin: 30px 10px 80px 10px;

  @media only screen and (max-width:1200px){
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`;

export const ViewLegend = styled.div`
  color: ${({active})=>active? colors.blue2: colors.white1};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  width: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 34px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
  }

  div {
    padding: 5px;
  }
  cursor: pointer;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ToggleMap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  > div {
    color: #0ccfe8;
    margin-left: 10px;
  }
`;

export const FloorCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background-color: ${colors.black5};
  letter-spacing: 2.5px;
  height: calc(100vh - 130px);
  min-height: 400px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    min-height: 460px;
  }

  @media only screen and (min-width: 501px) and (max-width: 767px) {
    min-height: 570px;
  }

  @media only screen and (min-width: 401px) and (max-width: 500px) {
    min-height: 490px;
  }

  @media only screen and (min-width: 375px) and (max-width: 400px)  {
    min-height: 460px;
  }

  @media only screen and (max-width: 374px) {
    min-height: 430px;
  }

  .type {
    font-size: 16px;
    font-weight: bold;
    color: ${colors.grey12};
  }

  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;
