import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateBooking from 'screens/CreateBooking';
import BookingGroup from 'screens/BookingGroup';
import BookingSingle from 'screens/BookingSingle';
import BookingCancelled from 'screens/BookingCancelled';
import Login from 'screens/Login';
import Staffs from 'screens/Staffs';
import Guest from 'screens/Guest';
import Staff from 'screens/Staff';
import ReviewPhotos from 'screens/ReviewPhotos';
import Home from 'screens/Home';
import Rooms from 'screens/Rooms';
import RoomDetails from 'screens/RoomDetails';
import RoomType from 'screens/RoomType';
import SiteMap from 'screens/SiteMap';
import Booking from 'screens/Booking';
import OccupancyReport from 'screens/OccupancyReport';
import BookingsReport from 'screens/BookingsReport';
import DailyReconciliation from 'screens/DailyReconciliation';
import RevenueReport from 'screens/RevenueReport';
import RoomNightReport from 'screens/RoomNightReport';
import Communications from 'screens/Communications';
import Search from 'screens/Search';
import PassportScanner from 'screens/PassportScanner';
import useAuth from 'customHook/useAuth';

const App = () => {
  
  const  { validToken } = useAuth()

  return (
    <Switch>
      {
        validToken?
          <> 
            <Route path="/login" exact component={Login} />
            <Route path="/site-map" exact component={SiteMap} />
            <Route path="/" exact component={Home} />
            <Route path="/bookings" exact component={Booking} />
            <Route path="/create-booking" exact component={CreateBooking} />
            <Route path="/rooms" exact component={Rooms} />
            <Route path="/room-details" exact component={RoomDetails} />
            <Route path="/staff" exact component={Staffs} />
            <Route path="/occupancy-report" exact component={OccupancyReport} />
            <Route path="/revenue-report" exact component={RevenueReport} />
            <Route path="/daily-reconciliation" exact component={DailyReconciliation} />
            <Route path="/bookings-report" exact component={BookingsReport} />
            <Route path="/room-night-report" exact component={RoomNightReport} />
            <Route path="/booking-group/:groupId" exact component={BookingGroup} />
            <Route path="/booking-single/:bookingId" exact component={BookingSingle} />
            <Route path="/booking-cancelled/:bookingId" exact component={BookingCancelled} />
            <Route path="/review-photos/:bookingId" exact component={ReviewPhotos} />
            <Route path="/staff/:staffId" exact component={Staff} />
            <Route path="/guest/:tenantId" exact component={Guest} />
            <Route path="/room-type/:roomTypeId" exact component={RoomType} />
            <Route path="/guest-communications/:tenantId" exact component={Communications} />
            <Route path="/staff-communications/:staffId" exact component={Communications} />
            <Route path="/search" exact component={Search} />
            <Route path="/passport-scanner" exact component={PassportScanner} />
          </> :
          <>
            <Redirect to="/login" />
            <Route path="/login" exact component={Login} />
          </>
      }
    </Switch>
  );
};

export default App;
