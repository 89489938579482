import React from 'react';
import { useHistory } from 'react-router-dom';
import { MessageBoxContainer, MessageItem } from './styles';
import ModalReplyFromDashboard from 'screens/ModalReplyFromDashboard';
import ArrowIcon from 'assets/svg/RightArrow2.svg';
import colors from 'constants/colors';

const MessageBox = ({ active }) => {
  
  const history = useHistory()

  const message = {
    name: 'Nancy Hachisu',
    tenantId: '981b25f5-8a30-4475-a383-8488e21863d0',
    roomNumber: '823',
    date: 'Text 20/6/2020 7:48PM',
    message: 'First line of message blah blah yada yada yada truncated…',
  }

  return (
    <MessageBoxContainer active={active}>
      <MessageItem>
        <div
          className='contentBox' 
          onClick={() => {
            history.push({pathname: `/guest/${message.tenantId}`, state: {communication: true}})
          }}
        >
          <div className='content'>
            <span className='messageWriter'>{message.name}</span>
            <span className='roomNumber'>{`Room ${message.roomNumber}`}</span>
          </div>
          <div className='dateTime'>{message.date}</div>
          <div className='message'>{message.message}</div>
        </div>
        <ModalReplyFromDashboard
          buttonLabel={" "}
          buttonStyle={`
            min-width: 45px;
            width: 100%;
            height: 100%;
            background: ${colors.blue1} url(${ArrowIcon}) no-repeat 50% 50%;
          `}
        />
      </MessageItem>
      <MessageItem>
        <div
          className='contentBox' 
          onClick={() => {
            history.push({pathname: `/guest/${message.tenantId}`, state: {communication: true}})
          }}
        >
          <div className='content'>
            <span className='messageWriter'>{message.name}</span>
            <span className='roomNumber'>{`Room ${message.roomNumber}`}</span>
          </div>
          <div className='dateTime'>{message.date}</div>
          <div className='message'>{message.message}</div>
        </div>
        <ModalReplyFromDashboard
          buttonLabel={" "}
          buttonStyle={`
            min-width: 45px;
            width: 100%;
            height: 100%;
            background: ${colors.blue1} url(${ArrowIcon}) no-repeat 50% 50%;
          `}
        />
      </MessageItem>
    </MessageBoxContainer>
  )
}

export default MessageBox;
