import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setRoom } from 'redux/actions'
import { useMutation } from '@apollo/client';
import { UPDATE_ROOM_NOTE_BY_ROOM_NOTE_ID } from 'graphql/service'
import colors from 'constants/colors';
import { Container } from './styles';
import PathIcon from 'assets/icons/path.svg';

const AlertItem = ({
  mobile,
  roomNoteId,
  isRead,
  roomNumber,
  roomId,
  alertType,
  notes,
  createdById,
  createdByName,
  checkInDate,
  checkOutDate
}) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const [updateNote] = useMutation(UPDATE_ROOM_NOTE_BY_ROOM_NOTE_ID);

  return (
    <Container
      mobile={mobile}
      onClick={() => {
        if(alertType==='ROOM NOTE') {
          dispatch(setRoom(roomNumber));
          if(!isRead) {
            updateNote({
              variables: {
                noteId: roomNoteId,
                input: {  
                  roomId: roomId,
                  notes: notes,
                  createdBy: createdById,
                  isRead: true,
                }
              }
            })
          }          
          history.push('/room-details')
        } else {
          history.push({pathname:'/bookings', state: {roomNumber: roomNumber}})
        }
      }}
      color={alertType === 'ROOM NOTE' ? colors.orange2 : colors.red3}
      isRead={isRead}
    >
      <div className='alertBody'>
        <div className='alertType'>{alertType}</div>
        <div className='content'>
          <div className='alertTitle'>({roomNumber}) {createdByName}: {notes}</div>
          <div className='dateTime'>
            <span>{checkInDate}</span>
            {checkOutDate? ' - ': null}
            <span>{checkOutDate}</span>
          </div>
        </div>
      </div>
      {
        isRead? null:
        (
          <div className='link'>
            <img src={PathIcon} alt="path-icon" />
          </div>
        )
      }
    </Container>
  )
}

export default AlertItem;
