import React from 'react';
import Header from 'components/Header';
import SubHeader from './components/SubHeader';
import 'react-calendar-timeline/lib/Timeline.css';
import useTimeline from 'customHook/useTimeline';
import TimelineTable from 'components/TimelineTable';
import ItemRenderer from './components/ItemRenderer';
import generalFakeData from './components/generate-fake-data';
import { SideBarItemContainer } from './styles';

const OccupancyReport = () => {
  const { timelineState } = useTimeline();
  const { groups, items } = generalFakeData();

  const SideBarItem = groups.map((groupItem) =>
    Object.assign({}, groupItem, {
      title: (
        <SideBarItemContainer>
          {groupItem.title}
        </SideBarItemContainer>
      )
    }),
  );

  return (
    <>
      <Header
        additionalStyle={`
          border: none;
        `}
      />
      <SubHeader timelineState={timelineState} />
      <TimelineTable
        timelineState={timelineState}
        SideBarItem={SideBarItem}
        items={items}
        itemRenderer={ItemRenderer}
        SideBarTitle=""
      />
    </>
  );
};

export default OccupancyReport;
