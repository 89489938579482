import React, { useState } from 'react'
import FloorCard from '../FloorCard'
import EighthFloor from '../FloorPlan/floors/EighthFloor'
import NinthFloor from '../FloorPlan/floors/NinthFloor'
import TenthFloor from '../FloorPlan/floors/TenthFloor'
import Legend from '../Legend'
import { useQuery } from '@apollo/client';
import { GET_ALL_ROOMS } from 'graphql/service';
import ActiveMapLegend from 'assets/icons/ActiveMapLegend.svg'
import MapLegend from 'assets/icons/MapLegend.svg'
import HideMap from 'assets/icons/HideMap.svg'
import ShowMap from 'assets/icons/ShowMap.svg'
import { Container, Header, ToggleMap, ViewLegend, MapRow, FloorCardContainer } from './style';
import colors from 'constants/colors'

const ManageRooms = () => {

  const [isMapVisible, setIsMapVisible] = useState(true)
  const [isLegendVisible, setIsLegendVisible] = useState(true)

  const { data: roomsData, loading, error } = useQuery(GET_ALL_ROOMS, {
    pollInterval: 5000,
  });
    
  if (error) {
    return <div>Error! {error.message}</div>;
  }
  if (loading) {
    return <div>Loading.............</div>;
  }

  const eighthFloorRooms = {}
  const ninthFloorRooms = {}
  const tenthFloorRooms = {}
  roomsData.validRooms.forEach(room => {
    switch (room.floor) {
      case '8':
        eighthFloorRooms[room.roomNumber] = room
        break
      case '9':
        ninthFloorRooms[room.roomNumber] = room
        break
      case '10':
        tenthFloorRooms[room.roomNumber] = room
        break
      default:
        break
    }
  })

  return (
    <Container>
      <Header>
        {
          isMapVisible ? (
            <>
              <ToggleMap onClick={() => setIsMapVisible(false)}>
                <img src={HideMap} alt={'hideMap'} />
                <div>HIDE MAP</div>
              </ToggleMap>
              <ViewLegend
                onClick={() => setIsLegendVisible(!isLegendVisible)}
                active={isLegendVisible}
              >
                {isLegendVisible ?
                  <img src={ActiveMapLegend} alt={'activeMapLegend'} /> :
                  <img src={MapLegend} alt={'mapLegend'} />}
                <div>{isLegendVisible ? 'HIDE LEGEND' : 'VIEW LEGEND'}</div>
              </ViewLegend>
            </>
          ) : (
            <ToggleMap onClick={() => setIsMapVisible(true)}>
              <img src={ShowMap} alt={'showMap'} />
              <div>SHOW MAP</div>
            </ToggleMap>
          )
        }
      </Header>
      { isMapVisible && isLegendVisible? <Legend desktop/> : null}
      {isMapVisible ? (
        <>
          <MapRow>
            <EighthFloor scale={1.25} backgroundColor={colors.black5} rooms={eighthFloorRooms}/>
            <NinthFloor scale={1.25} backgroundColor={colors.black5} rooms={ninthFloorRooms}/>
            <TenthFloor scale={1.25} backgroundColor={colors.black5} rooms={tenthFloorRooms}/>
          </MapRow>
          <FloorCardContainer>
            <FloorCard eighthFloorRooms={eighthFloorRooms} ninthFloorRooms={ninthFloorRooms} tenthFloorRooms={tenthFloorRooms}/>
          </FloorCardContainer>
        </>
      ) : null}
    </Container>
  );
};

export default ManageRooms;
