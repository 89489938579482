import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import { useMutation } from '@apollo/client';
import { CREATE_REFUND } from 'graphql/service';
import { Head, Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalRefund = ({hidden, buttonStyle, groupId, bookingId, refetch}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [refundType, setRefundType] = useState('Partial Refund')
  const [refundDetails, setRefundDetails] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const [createRefund] = useMutation(CREATE_REFUND, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleRefund = () => {

    createRefund({
      variables: {
        input: {
          charge: {
            type: refundType,
            note: refundDetails,
            bookingId: bookingId
          }
        },
      },
    });
    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Refund"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 373px;
          .close {
            z-index: 200;
          }
          .modal-body {
            position: relative;
            top: -30px;
            height: 100%;
          }
        }
        top: 139px;
      `}
    >
      <Head>Refund</Head>
      <Container>
        <Dropdown
          options={['Partial Refund']}
          value={refundType}
          title="SELECT TYPE"
          onChange={(e) => setRefundType(e)}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          value={refundDetails}
          title="REFUND DETAILS"
          onChange={(e) => setRefundDetails(e.target.value)}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
      </Container>
      <Footer>
        <Input
          value={refundAmount}
          title="REFUND AMOUNT"
          onChange={(e) => setRefundAmount(e.target.value)}
          additionalStyle={`
            width: 41%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleRefund}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalRefund;
