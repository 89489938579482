import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSecondaryCustomerInfo } from 'redux/actions'
import PhoneNumberInput from 'components/PhoneNumberInput';
import Input from 'components/Input';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { usePlacesWidget } from 'react-google-autocomplete'
import ModalRemoveSecondaryGuest from 'screens/ModalRemoveSecondaryGuest';

const SecondaryGuest = ({setSecondary, secondaryCustomerInfoError, setSecondaryCustomerInfoError }) => {

  const dispatch = useDispatch()
  const secondaryCustomerInfo = useSelector(state => state.secondaryCustomerInfoReducer.secondaryCustomerInfo)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
 
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_APIKEY,
    onPlaceSelected: (place) => {
      dispatch(setSecondaryCustomerInfo((secondaryCustomerInfo) => ({...secondaryCustomerInfo, secondaryAddress: place.formatted_address})))
    },
    options: {
      types: ['address'],
    },
  })

  useEffect(() => {
    if(phoneNumberValidity) {
      setSecondaryCustomerInfoError({...secondaryCustomerInfoError, secondaryPhoneNumberError: false})
      dispatch(setSecondaryCustomerInfo((secondaryCustomerInfo) => ({...secondaryCustomerInfo, secondaryPhoneNumber: phoneNumber})))
    }
  }, [phoneNumberValidity])

  return(
    <Card>
      <CardHeader>
        <div className='type'>SECONDARY GUEST</div>
        <div className='buttonContainer'>
          <ModalRemoveSecondaryGuest
            danger
            setSecondary={setSecondary}
            buttonStyle={`
              width: 103px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 120px;
              }

              @media only screen and (max-width: 767px) {
                width: 84px;
              }
            `}
          />
        </div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          title="NAME"
          value={secondaryCustomerInfo.secondaryFullName}
          error={secondaryCustomerInfoError.secondaryFullNameError}
          onChange={(e) => {
            dispatch(setSecondaryCustomerInfo({...secondaryCustomerInfo, secondaryFullName: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="ADDRESS"
          inputFieldRef={ref}
          value={secondaryCustomerInfo.secondaryAddress}
          error={secondaryCustomerInfoError.secondaryAddressError}
          onChange={(e) => {
            dispatch(setSecondaryCustomerInfo({...secondaryCustomerInfo, secondaryAddress: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Input
          mainPage
          title="EMAIL"
          value={secondaryCustomerInfo.secondaryEmail}
          error={secondaryCustomerInfoError.secondaryEmailError}
          onChange={(e) => {
            dispatch(setSecondaryCustomerInfo({...secondaryCustomerInfo, secondaryEmail: e.target.value}))
          }}
          additionalStyle={`
            flex-basis: 63%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <PhoneNumberInput
          mainPage
          title="PHONE"
          error={secondaryCustomerInfoError.secondaryPhoneNumberError}
          onChangeNumber={setPhoneNumber}
          setPhoneNumberValidity={setPhoneNumberValidity}
          onBlur={() => {
            if(!phoneNumberValidity&&phoneNumber.length>0) {
              setSecondaryCustomerInfoError({...secondaryCustomerInfoError, secondaryPhoneNumberError: true})
            }
          }}
          additionalStyle={`
            flex-basis: 35%;
            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
            }
          `}
        />
      </FormContainer>
    </Card>
  )
}

export default SecondaryGuest;
