import React from 'react';
import { useSelector } from 'react-redux'
import Header from 'components/Header';
import Messages from '../Home/components/Messages';
import Communications from 'screens/Communications';
import {
  MessagesContainer,
  Content,
  Container,
} from './styles';

const MessagesIndex = ({mobile, open}) => {

  const selectedGuest = useSelector(state => state.selectReducer.selectedGuest)

  return (
    <>
      <Header mobile={mobile} open={open}/>
      <MessagesContainer mobile={mobile} open={open}>
        <Content>
          <Container>  
            <Messages mobile/>
          </Container>
        </Content>
      </MessagesContainer>
      {
        selectedGuest? (
          <Communications
            mobile
            open={open}
          />
        ): null
      }
    </>
  )
}

export default MessagesIndex;
