import React from 'react';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { HistoryTable, HistoryContainer } from './styles';
import { Link } from 'react-router-dom';
import moment from 'moment';

const BookingHistory = ({ bookings }) => {

  return (
    <HistoryContainer>
      <Card>
        <CardHeader>
          <div className='type'>BOOKING HISTORY</div>
        </CardHeader>
      </Card>
      <HistoryTable>
        <thead>
          <tr>
            <th className="arrival">ARRIVAL</th>
            <th className="departure">DEPARTURE</th>
            <th className="roomType">ROOM TYPE</th>
            <th className="details"></th>
          </tr>
        </thead>
        <tbody>
          {
            bookings?.map((booking) => (
              <tr key={booking.bookingId}>
                <td>{moment(booking.checkInDate).format('M/D/YYYY h:mmA')}</td>
                <td>{moment(booking.checkOutDate).format('M/D/YYYY h:mmA')}</td>
                <td>{booking.roomBookings?.[0].room?.roomType?.name}</td>
                <td>
                  <Link
                    to={`/booking-single/${booking.bookingId}`}>
                    View booking details {'>'}
                  </Link>
                </td>
              </tr>            
            ))
          }
        </tbody>
      </HistoryTable>
    </HistoryContainer>
  );
};

export default BookingHistory;
