import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import ManageRooms from '../Home/components/ManageRooms'
import { RoomsContainer } from './styles'

const RoomsScreen = () => {

  return (
    <>
      <Header />
      <RoomsContainer>
        <ManageRooms />
      </RoomsContainer>
      <Footer />
    </>
  );
};

export default RoomsScreen;
