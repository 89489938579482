import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import { Head, Container, Footer } from './styles';

const ModalOverridePrice = ({hidden, buttonStyle, buttonLabel="Override Price", setPrice, setOverrideReason}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [reason, setReason] = useState('')
  const [newPrice, setNewPrice] = useState('')

  const handleOverride = () => {
    setOverrideReason(reason)
    setPrice(Number(newPrice))
    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 279px;
          .close {
            z-index: 200;
          }
          .modal-body {
            position: relative;
            top: -15px;
            height: 100%;
          }
        }
        top: 139px;
      `}
    >
      <Head>Override Room Price</Head>
      <Container>
        <Input
          title="REASON FOR OVERRIDE"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
      </Container>
      <Footer>
        <Input
          title="NEW PRICE"
          value={newPrice}
          onChange={(e) => setNewPrice(e.target.value)}
          additionalStyle={`
            width: 41%;
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleOverride}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalOverridePrice;
