import React from "react";
import Card from "components/Card";
import CardHeader from "components/CardHeader";
import ModalRefund from 'screens/ModalRefund';
import ModalAddDiscount from 'screens/ModalAddDiscount';
import ModalAddCharge from 'screens/ModalAddCharge';
import Note from "components/Note";
import moment from "moment";
import { GroupTotalContainer } from "./styles";

const GroupTotal = ({ billsArray, refetch, groupId }) => {

  return (
    <Card
      additionalStyle={`
        @media only screen and (max-width: 767px) {

          .type {
            width: 100%;
            margin-bottom: 10px; 
          }

          .buttonContainer {
            width: 100%;
            justify-content: space-between;
          }
          
          flex-direction: column;
          justify-content: center;
        }          
      `}
    >
      <CardHeader>
        <div className='type'>GROUP TOTAL</div>
        <div className='buttonContainer'>
          <ModalRefund
            buttonStyle={`
              width: 94px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 112px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 76px;
              }
            `}
            groupId={groupId}
            refetch={refetch}
          />
          <ModalAddDiscount
            buttonStyle={`
              width: 136px;
              margin-left: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 142px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 106px;
                margin-left: 10px;
              }
            `}
            groupId={groupId}
            refetch={refetch}
          />
          <ModalAddCharge
            buttonStyle={`
              width: 113px;
              margin-left: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 122px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 95px;
                margin-left: 10px;
              }
            `}
            groupId={groupId}
            refetch={refetch}
          />
        </div>
      </CardHeader>
      <div>
        {
          billsArray.length? billsArray.map((bills, index) => {
            const noteArray = bills?.map((bill, index) => {
              return (
                <Note
                  key={index}
                  date={moment(bill.updatedAt).format('M/D/YYYY h:mm A')}
                  description={bill.description}
                  price={bill.subTotal.toFixed(2)}
                  disabled
                />
              )
            })
            return noteArray
          }) : null
        }
      </div>
      <GroupTotalContainer>
        <div className="totalContainer">
          <span className="value">
            {
              billsArray.length? "$" + billsArray.reduce((acc, curr) => {
                const billSubTotal = curr?.reduce((acc, curr)=> 
                  acc + Number(curr.subTotal)
                , 0) || 0
                return acc + billSubTotal
              }, 0).toFixed(2): "$0.00" 
            }
          </span>
          <span className="subtotal">SUBTOTAL</span>
        </div>
        <div className="totalContainer">
          <span className="value">
            {
              billsArray.length? "$" + billsArray.reduce((acc, curr) => {
                const billTotal = curr?.reduce((acc, curr)=> 
                  acc + Number(curr.total)
                , 0) || 0
                return acc + billTotal
              }, 0).toFixed(2): "$0.00"
            }                  
          </span>
          <span className="total">TOTAL</span>
        </div>
      </GroupTotalContainer>
    </Card>
  )
}

export default GroupTotal;
