import React, { useState, useEffect } from 'react';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import ModalGroupBookingCancel from 'screens/ModalGroupBookingCancel';
import ModalOverridePrice from 'screens/ModalOverridePrice';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import FormContainer from 'components/FormContainer';
import { Row, RowContainer, TravelInputRow, InputRow } from './styles';
import { useLazyQuery } from '@apollo/client';
import {
  GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE,
  GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE
} from 'graphql/service';
import colors from 'constants/colors';
import { RESERVATION, ADVANCED } from 'constants/ratePlanId';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';
import moment from 'moment';

const GuestInformation = ({
  guestInfo,
  groupId,
  setOverrideReason,
  setGuestInfo,
}) => {

  const [types, setTypes] = useState({})
  const [originRoom, setOriginRoom] = useState({
    roomTypeName: guestInfo.roomType.name,
    roomNumber: guestInfo.roomNumber,
    roomId: guestInfo.roomId,
    checkInDate: guestInfo.checkInDate,
    checkOutDate: guestInfo.checkOutDate,
    price: guestInfo.price
  })
  const [prices, setPrices] = useState({})
  const [typedRooms, setTypedRooms] = useState({})

  const [
    getAvailableRoomTypesByDateRange,
    {
    data: availableRoomTypesData,
    loading: availableRoomTypesLoading,
    error: availableRoomTypesError
  }] = useLazyQuery(GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE)

  useEffect(()=> {
    getAvailableRoomTypesByDateRange({
      variables: {
        input: {
          checkInDate: guestInfo.checkInDate,
          checkOutDate: guestInfo.checkOutDate
        }
      }, 
      onCompleted: (availableRoomTypesData)=> {
        if(availableRoomTypesData) {
          const types = {}
          const prices = {}
          availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
            if(type.name!=='Amenities') {
              types[type.name] = type.roomTypeId
              prices[type.roomTypeId] = type.pricePerNight
            }        
          })
          types[guestInfo.roomType.name] = guestInfo.roomType.roomTypeId
          prices[guestInfo.roomType.roomTypeId] = guestInfo.price
          setTypes(types)
          setPrices(prices)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    })
  },[])

  const [
    getAvailableRoomsByRoomTypeIdAndDateRange,
    { data: roomsData, loading, error }
  ] = useLazyQuery(GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE)
  
  useEffect(()=> {
    getAvailableRoomsByRoomTypeIdAndDateRange({
      variables: {
        input: {
          roomTypeId: guestInfo.roomType.roomTypeId,
          checkInDate: guestInfo.checkInDate,
          checkOutDate: guestInfo.checkOutDate
        }
      },
      onCompleted: (roomsData) => {
        if(roomsData) {
          const typedRooms = {}
          roomsData.roomsAvailability.forEach((room)=> {
            typedRooms[room.roomNumber]=room.roomId 
          })
          typedRooms[guestInfo.roomNumber] = guestInfo.roomId
          setTypedRooms(typedRooms)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    })
  },[])

  const handleChangeRoomType = (e) => {
    if(e!==guestInfo.roomType.name) {                 
      getAvailableRoomsByRoomTypeIdAndDateRange({
        variables: {
          input: {
            roomTypeId: types[e],
            checkInDate: guestInfo.checkInDate,
            checkOutDate: guestInfo.checkOutDate
          }
        },
        onCompleted: (roomsData) => {
          if(roomsData) {
            const typedRooms = {}
            roomsData.roomsAvailability.forEach((room)=> {
              typedRooms[room.roomNumber]=room.roomId     
            })
            if(e===originRoom.roomTypeName) {
              typedRooms[originRoom.roomNumber] = originRoom.roomId
              setGuestInfo({
                ...guestInfo,
                roomType: {roomTypeId: types[e], name: e},
                price: prices[types[e]],
                roomNumber: originRoom.roomNumber,
                roomId: originRoom.roomId,
                roomChanged: false
              })
            } else {
              setGuestInfo({
                ...guestInfo,
                roomType: {roomTypeId: types[e], name: e},
                price: prices[types[e]],
                roomNumber: Object.keys(typedRooms)[0],
                roomId: typedRooms[Object.keys(typedRooms)[0]],
                roomChanged: true
              }) 
            }
            setTypedRooms(typedRooms)
          }
        },
        onError: (error) => {
          let content = error.message
          if(error.message.includes('Validation')) {
              content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
          }
          
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      })
    }  
  }

  const handleChangeRatePlan = (e) => {
    setGuestInfo({...guestInfo, ratePlanId: e})
  }

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader>
        <div className='type'>GUEST INFORMATION</div>
        <div className='buttonContainer'>
          <ModalGroupBookingCancel
            danger
            buttonLabel={'Remove'}
            buttonStyle={`
              width: 103px;
            `}
            bookingId={guestInfo.bookingId}
            groupId={groupId}
            isPrimaryTenant={guestInfo.isPrimaryTenant}
          />
        </div>
      </CardHeader>
      <FormContainer>
        <Input
          mainPage
          error={!guestInfo.guestName}
          title="NAME"
          value={guestInfo.guestName}
          onChange={(e) => {
            setGuestInfo({...guestInfo, guestName: e.target.value})
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <RowContainer>
          <Row>
            <Dropdown
              mainPage
              title="ROOM #"
              options={Object.keys(typedRooms)}
              value={guestInfo.roomNumber}
              onChange={(e) => {
                setGuestInfo({...guestInfo, roomNumber: e, roomId: typedRooms[e], roomChanged: true})
              }}
              additionalStyle={`
                flex-basis: 40%;
                margin-right: 10px;
              `}
            />
            <Dropdown
              mainPage
              title="ROOM TYPE"
              options={Object.keys(types)}
              value={guestInfo.roomType.name}
              onChange={handleChangeRoomType}
              additionalStyle={`
                flex-basis: 60%;
              `}
            />
          </Row>
          <Row>
            <InputRow>
              <div>
                <Dropdown
                  mainPage
                  options={[{label:'Advanced', value: ADVANCED}, {label:'Reservation', value: RESERVATION}]}
                  value={guestInfo.ratePlanId===ADVANCED? 'Advanced': 'Reservation'}
                  title="RATE PLAN"
                  onChange={handleChangeRatePlan}
                  disabled={true}
                  additionalStyle={`
                    width: 100%;
                  `}
                />
              </div>
              <div>
                <div className="price">
                  <div>PRICE</div>
                  <div>{guestInfo.price? '$' + guestInfo.price.toFixed(2): 'N/A'} {guestInfo.price===prices[guestInfo.roomType.roomTypeId]? null:<span className='highLight'>(Overridden)</span>}</div>
                </div>
                {
                  guestInfo.price===prices[guestInfo.roomType.roomTypeId]? 
                    <ModalOverridePrice
                      mainPage
                      price={guestInfo.price}
                      setPrice={(price) => setGuestInfo({...guestInfo, price: price})}
                      setOverrideReason={setOverrideReason}
                      buttonLabel={'Override'}
                      buttonStyle={`
                        width: 117px;
                        margin-top: 20px;
                        margin-left: 5px;
                      `}
                    />:
                    <Button
                      mainPage
                      onClick={() => {
                        setGuestInfo({...guestInfo, price: prices[guestInfo.roomType.roomTypeId]})
                        setOverrideReason('')
                      }}
                      additionalStyle={`
                        width: 52px;
                        margin-top: 20px;
                        margin-left: 5px;

                        @media only screen and (min-width: 767px) and (max-width: 1200px) {
                          width: 89px;
                        }

                        @media only screen and (max-width: 767px) {
                          width: 79px;
                        }
                      `}
                    >
                      Revert
                    </Button>
                }
              </div>
            </InputRow>
          </Row>
        </RowContainer>
        <TravelInputRow>
          <div>
            <div className="datePickerContainer">
              <span>ARRIVAL</span>
              <DatePicker
                error={guestInfo.checkInDate? guestInfo.checkInDate >= guestInfo.checkOutDate: false}
                minDate={moment(new Date())}
                dateValue={moment(guestInfo.checkInDate)}
                dateOnChange={(date) => {
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: date,
                        checkOutDate: guestInfo.checkOutDate
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[guestInfo.roomType.name] = guestInfo.roomType.roomTypeId
                        prices[guestInfo.roomType.name] = guestInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setGuestInfo({...guestInfo, checkInDate: date, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  })
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>ARRIVAL TIME</span>
              <TimePicker
                TimeValue={moment(guestInfo.checkInDate)}
                setTime={(e)=>{
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: e,
                        checkOutDate: guestInfo.checkOutDate
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[guestInfo.roomType.name] = guestInfo.roomType.roomTypeId
                        prices[guestInfo.roomType.name] = guestInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setGuestInfo({...guestInfo, checkInDate: e, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  }) 
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
          <div>
            <div className="datePickerContainer">
              <span>DEPARTURE</span>
              <DatePicker
                error={guestInfo.checkInDate? guestInfo.checkInDate >= guestInfo.checkOutDate: false}
                minDate={moment(new Date())}
                dateValue={moment(guestInfo.checkOutDate)}
                dateOnChange={(date) => {
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: guestInfo.checkOutDate,
                        checkOutDate: date
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[guestInfo.roomType.name] = guestInfo.roomType.roomTypeId
                        prices[guestInfo.roomType.name] = guestInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setGuestInfo({...guestInfo, checkOutDate: date, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  })
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 130px;
                  font-size: 18px;
                  font-weight: 600;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
            <div className="timePickerContainer">
              <span>DEPARTURE TIME</span>
              <TimePicker
                TimeValue={moment(guestInfo.checkOutDate)}
                setTime={(e)=>{
                  getAvailableRoomTypesByDateRange({
                    variables: {
                      input: {
                        checkInDate: guestInfo.checkInDate,
                        checkOutDate: e
                      }
                    }, 
                    onCompleted: (availableRoomTypesData)=> {
                      if(availableRoomTypesData) {
                        const types = {}
                        const prices = {}
                        availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
                          if(type.name!=='Amenities') {
                            types[type.name] = type.roomTypeId
                            prices[type.name] = type.pricePerNight
                          }        
                        })
                        types[guestInfo.roomType.name] = guestInfo.roomType.roomTypeId
                        prices[guestInfo.roomType.name] = guestInfo.price
                        setTypes(types)
                        setPrices(prices)
                        setGuestInfo({...guestInfo, checkOutDate: e, dateTimeChanged: true})
                      }
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  }) 
                }}
                additionalStyle={`
                  width: 100%;
                  min-width: 110px;
                  border: 1px solid ${colors.grey1};

                  input {
                    padding-left: 40px;
                    padding-right: 0px;
                  }
                `}
              />
            </div>
          </div>
        </TravelInputRow>
      </FormContainer>
    </Card>
  )
}

export default GuestInformation;