import React from 'react';
import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/styles";
import { PhoneNumberInputContainer } from './style';

const PhoneNumberInput = ({
  width,
  title,
  mainPage,
  additionalStyle,
  marginBottom,
  disable,
  placeholder,
  onBlur,
  onChangeNumber,
  setPhoneNumberValidity,
  error,
  errorMessage
}) => {
  return (
    <PhoneNumberInputContainer
      mainPage={mainPage}
      additionalStyle={additionalStyle}
      error={error}
      disable={disable}
      width={width}
      marginBottom={marginBottom}
    >
      {title? <label>{title}</label>: null}
      <IntlTelInput
        initOptions={{ 
          initialCountry: 'ca',
          placeholderNumberType	: 'MOBILE',
          autoPlaceholder: 'off',
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.10/build/js/utils.js"
        }}
        inputProps={{
          placeholder: placeholder,
          onBlur: onBlur,
        }}
        onChangeNumber={onChangeNumber}
        onChangeValidity={setPhoneNumberValidity}
      />
      {error? <div className="warning">{errorMessage? errorMessage:'Please enter a valid phone number'}</div>: null}
    </PhoneNumberInputContainer>
  )
}

export default PhoneNumberInput;
