import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Input from 'components/Input'
import Button from 'components/Button'
import useAuth from 'customHook/useAuth'
import { Container, ContentContainer } from './styles'
import Logo from 'assets/Logo.png'

const Login = () => {
  
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const { userLogin } = useAuth();
  
  const login = () => {
    if (email.length > 0) {
      if(password.length > 6) {        
        userLogin(email, password, setPasswordError)
      } else {
        setPasswordError(true)
      }
    } else {
      setEmailError(true)
    }
  }

  const forgetPassword = () => {
    history.pushState('/forgetPassword')
  }

  return (
    <Container>
      <ContentContainer>
        <img src={Logo} alt="Logo" />
        <div className='brand'>
          <span className='rook'>ROOK </span>
          <span className='hotel'>HOTEL</span>
        </div>
        <Input
          error={emailError}
          placeholder={'Email Address'}
          onChange={(e) => setEmail(e.target.value)}
          additionalStyle={`
            width: 100%;
            margin-top: 10px;
          `}
        />
        {
          emailError? <div className='errorMessage'>Email Address cannot be empty.</div>: null
        }
        <Input
          error={passwordError}
          placeholder={'Password'}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          additionalStyle={`
            width: 100%;
          `}
        />
        { 
          passwordError? <div className='errorMessage'>Incorrect password. Please try again.</div>: null
        }
        <div
          className='highLight'
          onClick={forgetPassword}
        >
          Can’t remember your password?
        </div>
        <Button
          mainPage
          onClick={login}
        >
          Log In
        </Button>
      </ContentContainer>
    </Container>
  );
};

export default Login;
