import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import useWindowDimensions from 'customHook/useWindowDimensions';
import { InputRow } from './styles';

const GroupBookingInfo = ({
  groupId,
  tenantList,
  tenantName,
  groupName,
  setGroupName,
}) => {
  
  const history = useHistory()
  const { width } = useWindowDimensions()

  return (
    <Card>
      <CardHeader>
        <div className='type'>GROUP BOOKING INFO</div>
        <div className='buttonContainer'>
          <Button
            mainPage
            onClick={() => {
              history.push(`/booking-group/${groupId}`)
            }}
            additionalStyle={`
              width: 175px;

              @media only screen and (max-width: 767px) {
                width: 110px;
              }
            `}
          >
            {width>767? 'View Group Booking': 'View Group'}
          </Button>
        </div>
      </CardHeader>
      <InputRow>
        <Input
          mainPage
          title="GROUP NAME"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          additionalStyle={`
            flex-basis: 35%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <Dropdown
          mainPage
          options={tenantList}
          value={tenantName}
          title="SKIP TO ANOTHER BOOKING IN THIS GROUP"
          onChange={(e) => {
            history.push(`/booking-single/${e}`)
          }}
          additionalStyle={`
            flex-basis: 63%;

            .select {
              background-position: 97% 50%;
            }

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-top: 25px;
              span {
                letter-spacing: 2px;
              }
            }
          `}
        />
      </InputRow>
    </Card>
  );
};

export default GroupBookingInfo;
