import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStaffInfo } from 'redux/actions'
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import StaffInformation from './components/StaffInformation';
import StaffInformationImages from './components/StaffInformationImages';
import MetaInformation from './components/MetaInformation';
import GeneralStaffNotes from './components/GeneralStaffNotes';
import WorkSchedule from './components/WorkSchedule';
import SendStaffMessage from './components/SendStaffMessage';
import CommunicationHistory from './components/CommunicationHistory';
import { useQuery } from '@apollo/client';
import { GET_STAFF_INFO_BY_STAFF_ID } from 'graphql/service';
import { useStaffForm } from 'customHook/useStaffForm';
import {
  Content,
  ContentColumn,
  Container,
  DesktopCommunicationContainer,
  MobileCommunicationContainer
} from 'components/BookingMain/styles';
import ChatIcon from 'assets/icons/chat.svg'
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const StaffScreen = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const staffInfo = useSelector(state => state.staffInfoReducer.staffInfo)
  const staffId= useParams().staffId

  //GeneralStaffNotes
  const [staffNotes, setStaffNotes] = useState([])

  const { data: staffInfoData, loading, error, refetch } = useQuery(
    GET_STAFF_INFO_BY_STAFF_ID,
    {
      variables: { staffId: staffId },
      onCompleted: (staffInfoData) => {
        if(staffInfoData) {
          const newStaff = {...staffInfo}
          const {
            user: {
              userId,
              fullName,
              email,
              phoneNumber,
              address,
              status,
              userRoles: {
                name: roles
              }
            },
            staffNotes
          } = staffInfoData.staffByID
          newStaff.userId = userId
          newStaff.staffId = staffId
          newStaff.fullName = fullName
          newStaff.email = email
          newStaff.phoneNumber = phoneNumber
          newStaff.address = address
          newStaff.roles = roles
          newStaff.status = status
          dispatch(setStaffInfo(newStaff))
          setStaffNotes(staffNotes)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )
  
  const [staffInfoError, setStaffInfoError] = useState({})
  const { updateStaffInfo } = useStaffForm(setStaffInfoError)

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  const handleSaveChanges = () => {
    updateStaffInfo()
  }

  return (
    <>
      <Header
        subHeader={
          <>
            <div className='title'>
              Staff Profile
            </div>
            <div className='buttonContainer'>
              <div>
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalStyle={`
                    width: 122px;
                    margin-left: 14px;
                  `}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </>
        }      
      />
      <Content>
        <MobileCommunicationContainer>
          <Button
            mainPage
            onClick={()=>{
              history.push(`/staff-communications/${staffId}`)
            }}
            additionalStyle={`

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 308px;
                height: 55px;
                font-size: 15px;
              }

              @media only screen and (max-width: 767px) {
                width: 100%;
                height: 55px;
                font-size: 15px;
              }
            `}
          >
            <img src={ChatIcon} alt={'chat'} width={29} height={29}/>
            {`Communications with ${staffInfo.fullName}`}
          </Button>
        </MobileCommunicationContainer>
        <ContentColumn>
          <Container>
            <StaffInformation
              staffInfoError={staffInfoError}
              setStaffInfoError={setStaffInfoError}
            />
            <StaffInformationImages />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Referral"}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <GeneralStaffNotes
              notes={staffNotes}
              refetch={refetch}
              staffId={staffId}
            />
          </Container>
          <Container>
            <WorkSchedule />
          </Container>
        </ContentColumn>
        <DesktopCommunicationContainer>
          <SendStaffMessage />
          <CommunicationHistory />
        </DesktopCommunicationContainer>
      </Content>
      <Footer />
    </>
  );
};

export default StaffScreen;
