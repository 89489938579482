import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentInfo, cleanPaymentInfo } from 'redux/actions'
import { usePaymentForm } from 'customHook/usePaymentForm';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import Inputmask from 'react-input-mask';
import {
  creditCardMask,
  expiryDateMask,
  cvvMask,
} from './inputMasks'
import { detectCardType } from 'utils/detectCardType';
import Autocomplete from 'react-google-autocomplete'
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT_CARD } from 'graphql/service';
import checkMark from 'assets/svg/checkMark.svg'
import { Container, AutocompleteContainer, Head, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalBookingCancelChangeCard = ({hidden, danger, buttonLabel="Cancel Change Card", buttonStyle}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [creditCardType, setCreditCardType] = useState('')
  const [coverInput, setCoverInput] = useState('')
  const dispatch = useDispatch()
  const paymentInfo = useSelector(state => state.paymentInfoReducer.paymentInfo)
  const { paymentError, setPaymentError, isValidatePaymentInfo } = usePaymentForm(creditCardType)

  const [createPaymentCard] = useMutation(CREATE_PAYMENT_CARD, 
    {
      onCompleted: (data) => {
        if(data) {
          message.success('Add payment card successfully!')
          setIsOpen(false)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  useEffect(()=> {
    return () => {
      dispatch(cleanPaymentInfo())
      setCoverInput('')
      creditCardMask.mask = '9999 9999 9999 9999'
    }    
  },[dispatch])

  const cleanFunction = () => {
    const googleElements = document.getElementsByClassName("pac-container")
    googleElements[googleElements.length-1].remove()
  }

  const onChangeCreditCard = (e) => {
    
    setCoverInput(e.target.value)
    setPaymentInfo((paymentInfo) => ({...paymentInfo, creditCardNumber: e.target.value}))
    if(e.target.value.length < 19) {
      setCreditCardType('')
      setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
    } else {
      const type = detectCardType(e.target.value.replace(/\s/g, ''))
      if(type.length > 0) {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
      } else {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
      }
      setCreditCardType(type)
    }
  }

  //add payment card function for valid form
  const handleCancel = () => {
    if (isValidatePaymentInfo()) {
      createPaymentCard({
        variables: {
          input: {
            cardHolderName: paymentInfo.cardHolderName,
            creditCardNumber: paymentInfo.creditCardNumber.replace(/\s/g, ''),
            cardExpirationDate: paymentInfo.cardExpirationDate.replace(/[\s/]/g, ''),
            cardCVV: paymentInfo.cardCVV,
            billingAddress: paymentInfo.billingAddress,
          }
        }
      })
    } else {
      const content = 'Please fill the form!'
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  };

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      modalTitle="Credit Card Required"
      cleanFunction={cleanFunction}
      buttonLabel={buttonLabel}
      danger={danger}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 679px;
        .modal-content{
          height: 543px;
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: -10px;
            .modal-title {
              font-size: 22px;
              font-weight: 600;
            }
          }          
          .modal-body {
            position: relative;
            top: 5px;
            height: 100%;
          }
        }
        top: 66px;
      `}
    >
      <Container>
        <Head>
          You have cancelled the booking belonging to the Primary Contact for this
          group.{' '}
        </Head>
        <Dropdown
          options={['-Select-']}
          value={''}
          title="ASSIGN ANOTHER MEMBER AS THE PRIMARY CONTACT"
          onChange={(e) => { }}
          additionalStyle={`
            flex-basis: 100%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
        />
        <Input
          title="NAME"
          value={paymentInfo.cardHolderName}
          error={paymentError.cardHolderNameError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardHolderName: e.target.value}))
            if(paymentError.cardHolderNameError) {
              setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: false}))
            }
          }}
          additionalStyle={`
            flex-basis: 100%;
          `}
        />
        <Inputmask
          mask={creditCardMask.mask}
          maskPlaceholder=''
          value={coverInput}
          error={paymentError.creditCardNumberError}
          onChange={onChangeCreditCard}
        >
          <Input
            title="CREDIT CARD NUMBER"
            maxLength={19}
            additionalStyle={`
              flex-basis: 60%;
              input{
                ${
                  creditCardType? `
                    background-image: url(${checkMark});
                    background-repeat: no-repeat;
                    background-position: 80% 50%;
                  `: null
                }
              }
            `}
          />
        </Inputmask>
        <Inputmask
          mask={expiryDateMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardExpirationDate}
          error={paymentError.cardExpirationDateError}
          onChange={(e)=> {
            dispatch(setPaymentInfo({...paymentInfo, cardExpirationDate: e.target.value}))
            if(paymentError.cardExpirationDateError) {
              setPaymentError((paymentError)=>({...paymentError, cardExpirationDateError: false}))
            }
          }}
        >
          <Input
            title="EXPIRY"
            additionalStyle={`
              flex-basis: 18%;
              margin-left:2%;
            `}
          />
        </Inputmask>
        <Inputmask
          mask={cvvMask.mask}
          maskPlaceholder=''
          value={paymentInfo.cardCVV}
          error={paymentError.cardCVVError}
          onChange={(e) => {
            dispatch(setPaymentInfo({...paymentInfo, cardCVV: e.target.value}))
            if(paymentError.cardCVVError) {
              setPaymentError((paymentError)=>({...paymentError, cardCVVError: false}))
            }
          }}
        >
          <Input      
            title="CCV"
            additionalStyle={`
              flex-basis: 18%;
              margin-left:2%;
            `}
          />
        </Inputmask>
        <AutocompleteContainer>
          <span>ADDRESS</span>
          <Autocomplete
            autoComplete="new-password"
            apiKey={process.env.REACT_APP_GOOGLE_APIKEY}
            value={paymentInfo.billingAddress}
            error={paymentError.billingAddressError}
            onChange={(e) => {
              dispatch(setPaymentInfo({...paymentInfo, billingAddress: e.target.value}))
            }}
            onPlaceSelected={(place) => {
              dispatch(setPaymentInfo({...paymentInfo, billingAddress: place.formatted_address}))
            }}
          />
        </AutocompleteContainer>
      </Container>
      <Footer>
        <Button
          danger
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleCancel}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  )
}

export default ModalBookingCancelChangeCard;
