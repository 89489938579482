import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import { GENERATE_KEY } from 'graphql/service';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { Container, Head, Footer } from './styles';
import { getMessageTimeOut } from 'utils/util';

const ModalGenerateKey = ({hidden, buttonStyle, buttonLabel="Create Key", roomNumber, deviceId, setDeviceId, setKeyPrintModalIsOpen}) => {
  
  const [isOpen, setIsOpen] = useState(false)
  const [generateKey] = useMutation(GENERATE_KEY, {
    onCompleted: (data) => {
      if(data) {
        message.success('Key generated successfully!')
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleGenerateKey = () => {
    generateKey({
      variables: {
        input: { 
          roomNumber: roomNumber,
          deviceId: deviceId
        }
      },
    })
    setIsOpen(false)
    setKeyPrintModalIsOpen(true)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel={buttonLabel}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 230px;
          .close {
            z-index: 200;
          }          
          .modal-body {
            position: relative;
            top: -45px;
            height: 100%;
          }
        }
        top: 239px;
      `}
    >
      <Container>
        <Head>Generate New Key</Head>
        <Dropdown        
          options={[
            'Kiosk 1',
            'Kiosk 2',
            'Kiosk 3',
            'Kiosk 4',
            'Kiosk 5',
            'Kiosk 6'
          ]}
          value={deviceId}
          title="PRINT AT KIOSK"
          onChange={(e) => setDeviceId(e)}
          additionalStyle={`
            flex-basis: 100%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
        />
      </Container>
      <Footer>
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleGenerateKey}
        >
          Print
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalGenerateKey;
