import React, { useState, useEffect } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {
  GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE,
  GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE,
  UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID
} from 'graphql/service';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import statusMap from 'constants/statusMap';
import { Head, Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalChangeRoom = ({
  hidden,
  buttonStyle,
  roomNumber,
  roomId,
  roomStatus,
  roomType,
  checkInDate,
  checkOutDate,
  bookingId,
  refetch
}) => {

  const [selectedRoomType, setSelectedRoomType] = useState(roomType.name)
  const [selectedRoom, setSelectedRoom] = useState(roomNumber)
  const [types, setTypes] = useState({})
  const [typedRooms, setTypedRooms] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  const {
    data: availableRoomTypesData,
  } = useQuery(
    GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE, 
    {
      variables: {
        input: {
          checkInDate,
          checkOutDate,
          roomQuantity: 1,
          guestQuantity: 1,
          isWheelchairAccessible: false
        }
      }, 
      onCompleted: (availableRoomTypesData)=> {
        if(availableRoomTypesData) {
          const types = {}
          availableRoomTypesData.availableRoomTypesByDateRange.forEach((type) => {
            if(type.name!=='Amenities') {
              types[type.name] = type.roomTypeId
            }        
          })
          types[roomType.name] = roomType.roomTypeId
          setTypes(types)
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  useEffect(()=> {
    setSelectedRoomType(roomType.name)
    setSelectedRoom(`${roomNumber} (${roomStatus})`)
  },[roomType.name, roomStatus, roomNumber])

  const [
    getAvailableRoomsByRoomTypeIdAndDateRange,
  ] = useLazyQuery(GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE, {
    variables: {
      input: {
        roomTypeId: types[selectedRoomType],
        checkInDate,
        checkOutDate 
      }
    },
    onCompleted: (roomsData) => {
      if(roomsData) {
        const typedRooms ={}
        roomsData.roomsAvailability.forEach((room)=> {
          typedRooms[`${room.roomNumber} (${statusMap[room.roomStatusHistories?.[0]?.status]})`] = room.roomId
        })
        if(selectedRoomType===roomType.name) {
          typedRooms[`${roomNumber} (${roomStatus})`] = roomType.roomTypeId
        }            
        setTypedRooms(typedRooms)
        if(selectedRoomType===roomType.name) {
          setSelectedRoom(`${roomNumber} (${roomStatus})`)
        } else {
          setSelectedRoom(Object.keys(typedRooms)[0])
        }
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  useEffect(()=> {
    if(selectedRoomType&&Object.keys(types).length) {
      getAvailableRoomsByRoomTypeIdAndDateRange()
    }
  },[selectedRoomType, types, getAvailableRoomsByRoomTypeIdAndDateRange])

  const [updateRoomBooking] = useMutation(
    UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID,
    {
      onCompleted: refetch,
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleChangeRoom = () => {
    updateRoomBooking({
      variables: {
        input: {
          roomId: typedRooms[selectedRoom],
          bookingId: bookingId 
        },
      },
    })

    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Change Room"
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 444px;
        .modal-content{
          height: 263px;
          .close {
            z-index: 200;
          }
          .modal-body{
            position: relative;
            top: -25px;
            height: 100%;
          }
        }
        top: 203px;
      `}
    >
      <Head>Change Room</Head>
      <Container>
        <Dropdown          
          options={Object.keys(types)}
          value={selectedRoomType}
          title="SELECT TYPE"
          onChange={(e) => setSelectedRoomType(e)}
          additionalStyle={`
            flex-basis: 100%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
        />
      </Container>
      <Footer>
        <Dropdown
          options={Object.keys(typedRooms)}
          value={selectedRoom}
          title="SELECT ROOM"
          onChange={(e) => setSelectedRoom(e)}
          additionalStyle={`
            flex-basis: 64%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
        />
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleChangeRoom}
        >
          Submit
        </Button>
      </Footer>
    </MainModal>
  );
}

export default ModalChangeRoom;
