import styled from 'styled-components';
import colors from 'constants/colors';
import trash from 'assets/icons/trash.svg'

export const PrimaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 2px solid ${colors.grey1};
  border-bottom: 2px solid ${colors.grey1};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-top: 2px solid ${colors.grey1};
  margin-top: 34px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    margin-top: 38px;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 19px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 2px solid ${colors.grey1};

  .status {
    font-style: italic;
    font-weight: 300;
  }

  .primaryButton {
    font-size: 14px;
    font-weight: bold;
    color: ${colors.hoverBlue};
    cursor: pointer;
  }

  .warningButton {
    text-align: right;
    width: 100px;
    font-size: 14px;
    font-weight: bold;
    color: ${colors.red};
    cursor: pointer;
    background-image: url(${trash});
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 0 50%;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
    height: 36px;
    line-height: 36px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
    height: 33px;
    line-height: 33px;
  }
`;

export const Warning = styled.div`
  color: ${colors.red1};
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.5px;
  margin-bottom: 10px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }
`;