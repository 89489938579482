import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cleanSecondaryCustomerInfo } from 'redux/actions'
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import colors from 'constants/colors';
import { Container } from './styles';

const ModalRemoveSecondaryGuest = ({hidden, danger, buttonStyle, setSecondary}) => {

  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const handleRemoveGuest = () => {
    dispatch(cleanSecondaryCustomerInfo())
    setSecondary(false)
  }
  
  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      buttonLabel="Remove"
      danger={danger}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 510px;
        .modal-content{
          height: 212px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            top: -5px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 216px;
      `}
    >
      <Container>
        <div>Are you sure you want to remove this guest?</div>
        <Button
          danger
          additionalStyle={`
            height: 53px;
            width: 168px;
          `}
          onClick={handleRemoveGuest}
        >
          Remove Guest
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalRemoveSecondaryGuest;
