import { 
    RESET_REDUX, 
    SET_USER,
    SET_ROOM,
    SET_GUEST,
    CLEAN_SELECTION,
    CLEAN_GUEST,
    SET_CUSTOMER_INFO,
    SET_SECONDARY_CUSTOMER_INFO,
    CLEAN_SECONDARY_CUSTOMER_INFO,
    SET_STAFF_INFO,
    SET_PAYMENT_INFO,
    CLEAN_PAYMENT_INFO,
    SET_PICKED_DATE,
} from './action-types'

export const resetRedux = () => ({
    type: RESET_REDUX,
    data: null
})

export const saveUser = (user) => ({
    type: SET_USER,
    data: user
})

export const setRoom = (selectedRoom) => ({
    type: SET_ROOM,
    data: selectedRoom
})

export const setGuest = (tenantId, type) => ({
    type: SET_GUEST,
    data: {tenantId, type}
})

export const cleanSelection = () => ({
    type: CLEAN_SELECTION,
    data: null
})

export const cleanGuest = () => ({
    type: CLEAN_GUEST,
    data: null
})

export const setCustomerInfo = (customerInfo) => ({
    type: SET_CUSTOMER_INFO,
    data: customerInfo
})

export const setSecondaryCustomerInfo = (secondaryCustomerInfo) => ({
    type: SET_SECONDARY_CUSTOMER_INFO,
    data: secondaryCustomerInfo
})

export const cleanSecondaryCustomerInfo = () => ({
    type: CLEAN_SECONDARY_CUSTOMER_INFO,
    data: null
})

export const setStaffInfo = (staffInfo) => ({
    type: SET_STAFF_INFO,
    data: staffInfo
})

export const setPaymentInfo = (paymentInfo) => ({
    type: SET_PAYMENT_INFO,
    data: paymentInfo
})

export const cleanPaymentInfo = () => ({
    type: CLEAN_PAYMENT_INFO,
    data: null
})

export const setPickedDate = (pickedDate) => ({
    type: SET_PICKED_DATE,
    data: pickedDate
})