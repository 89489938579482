import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import { useMutation } from '@apollo/client';
import { DELETE_GROUP_BY_GROUP_ID } from 'graphql/service';
import { Container, Footer } from './styles';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const ModalGroupCancel = ({groupId, hidden, buttonStyle, danger, buttonLabel="Cancel Group" }) => {
  
  const history= useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [cancellationOption, setCancellationOption] = useState('') 
  const [cancellationReason, setCancellationReason] = useState('')  
  const [cancellationFee, setCancellationFee] = useState(true)
  const [deleteGroupByGroupId] = useMutation(
    DELETE_GROUP_BY_GROUP_ID,
    {
      onCompleted: (data) => {
        if(data.deleteGroup.isDeleted === true) {
          message.success('Group has been deleted')
          history.push('/')
        } else {
          const content = 'Delete group failed'
          message.error({
            content,
            duration: getMessageTimeOut(content),
            onClick: () => {
              message.destroy()
            }
          })
        }
      },
      onError: (error) => {
        let content = error.message
        if(error.message.includes('Validation')) {
            content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
        }
        
        message.error({
          content,
          duration: getMessageTimeOut(content),
          onClick: () => {
            message.destroy()
          }
        })
      }
    }
  )

  const handleCancel = () => {
    deleteGroupByGroupId({
      variables: {
        groupId: groupId,
        input: {
          cancellationReason: cancellationOption || cancellationReason,
          cancellationFee: cancellationFee
        }
      }
    })
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-danger"
      modalTitle="Cancel this group?"
      buttonLabel={buttonLabel}
      danger={danger}
      buttonStyle={buttonStyle}
      hidden={hidden}
      additionalStyle={`
        max-width: 643px;
        .modal-content{
          height: 321px;
          .close {
            z-index: 200;
          }
          .modal-header {
            position: relative;
            top: -10px;
            .modal-title {
              font-size: 22px;
              font-weight: 600;
            }
          }          
          .modal-body {
            position: relative;
            top: 0px;
            height: 100%;
          }
        }
        top: 216px;
      `}
    >
      <Container>
        <Dropdown
          name="reason"
          options={['','1', '2', '3']}
          title="REASON FOR CANCELLATION"
          additionalStyle={`
            flex-basis: 100%;
            .select {
              font-style: italic;
              font-weight: 300;
            }
          `}
          value={cancellationOption}
          onChange={(e) => {
            setCancellationReason('')
            setCancellationOption(e)
          }}
        />
        <TextArea
          title="TYPE A REASON HERE:"
          value={cancellationReason}
          onChange={(e) => {
            setCancellationOption('')
            setCancellationReason(e.target.value)
          }}
          additionalStyle={`
            flex-basis: 100%;
            
            textarea {
              min-height: 54px;
              height: 54px;
            }
          `}
        />
      </Container>
      <Footer>
        <Checkbox
          name="cancellationFee"
          checked={cancellationFee}
          onChange={(e) => setCancellationFee(e)}
          title="Apply cancellation fee"
        />
        <Button
          danger
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleCancel}
        >
          Apply
        </Button>
      </Footer>
    </MainModal>
  );
};

export default ModalGroupCancel;
