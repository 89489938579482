import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  top: 0px;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 180px;
  margin: 15px 0 12px 0;

  input {
    height: 49px;
    background-position: 98% 50%;
    padding: 9px 22px;
  }

  span {
    font-weight: 300;
  }
`;

export const StyledList = styled.div`
  width: 100%;
  border: solid ${colors.grey1};
  border-width: 0px 1px 1px 1px;
`;

export const StyledItem = styled.div`
  width: 100%;
  height: 36px;
  line-height: 35px;
  font-size: 14px;
  font-weight: normal;
  padding-left: 20px;
  color: ${colors.grey24};
  background-color: ${colors.black5};
  overflow: hidden;

  .highLight {
    font-weight: bold;
    color: ${colors.white1};
  }

  :hover {
    color: ${colors.blue1};
    border-left: 5px solid ${colors.blue1};
    background-color: ${colors.black9};
    padding-left: 15px;

    .highLight {
      color: ${colors.blue1};
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
