import React, { useState } from 'react';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import ModalGenerateKey from 'screens/ModalGenerateKey';
import ModalKeyPrinting from 'screens/ModalKeyPrinting';
import { RoomDetailsHeaderContainer, Title, Row } from './styles';
import { UPDATE_ROOM_STATUS, INVALIDATE_KEY, UNLOCK_DOOR } from 'graphql/service';
import { useMutation } from '@apollo/client';
import statusMap from 'constants/statusMap';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const RoomDetailsHeader = ({ roomNumber, roomId, roomType: { name, outside }, status, refetch }) => {

  const [updateRoomStatus] = useMutation(UPDATE_ROOM_STATUS, {
    onCompleted: refetch,
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const [unlockDoor] = useMutation(UNLOCK_DOOR)
  const [invalidateKey] = useMutation(INVALIDATE_KEY)

  const [deviceId, setDeviceId] = useState('Kiosk 1')
  const [KeyPrintModalIsOpen, setKeyPrintModalIsOpen] = useState(false)

  const statusOptions = (roomNumber>=827 && roomNumber<=833)? 
  [
    'Room Inspected',
    'Room Clean',
    'Room Dirty',
  ]: [
    'Occupied',
    'Occupied(Check out today)',
    'Occupied(Stayover, Action Req)',
    'Room Inspected',
    'Room Clean',
    'Out of Order',
    'Room Dirty',
  ]

  const handleChangeRoomStatus = (e) => {
    const statusKey = Object.keys(statusMap).find(key => statusMap[key] === e);
    updateRoomStatus({
      variables: {
        input: {
          roomId: roomId,
          status: statusKey
        },
      },
    })
  }

  const handleUnlockDoor = () => {
    unlockDoor({
      variables: {
        input: { 
          roomNumber: roomNumber
        }
      },
    })
  }

  const handleInvalidateKey = () => {
    invalidateKey({
      variables: {
        input: { 
          roomNumber: roomNumber
        }
      },
    })
  }

  return (
    <RoomDetailsHeaderContainer>
      <Title>
        <div className='title'>
          <div className='roomNumber'>ROOM {roomNumber}</div>
          <div className='secondary'>
            {name.toUpperCase()} {outside && 'OUTSIDE'}
          </div>
        </div>
        <Dropdown
          mainPage
          options={statusOptions}
          value={statusMap[status]}
          onChange={handleChangeRoomStatus}
          additionalStyle={`
            .select {
              height: 46px;
              font-size: 20px;
              font-weight: normal;
            }

            @media only screen and (min-width: 1201px) {
              display: none;
            }

            @media only screen and (min-width: 767px) and (max-width: 1200px) {
              width: 300px;
            }

            @media only screen and (max-width: 767px) {
              flex-basis: 70%;
              min-width: 250px;
              max-width: 300px;
              .select {
                height: 37px;
                font-size: 13px;
              }
            }
          `}
        />
      </Title>
      <Row>
        <Dropdown
          mainPage
          options={statusOptions}
          value={statusMap[status]}
          onChange={handleChangeRoomStatus}
          title="ROOM STATUS"
          additionalStyle={`
            width: 300px;
            margin-right: 18px;
            @media only screen and (max-width: 1200px) {
              display: none;
            }
          `}
        />
        <div className='buttonContainer'>
          <Button
            mainPage
            additionalStyle={`
              width: 123px;
              margin-right: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 148px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 100px;
                margin-right: 5px;
              }
            `}
            onClick={handleUnlockDoor}
          >
            Unlock Door
          </Button>
          <Button
            mainPage
            additionalStyle={`
              width: 134px;
              margin-right: 14px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 163px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 100px;
                margin-right: 5px;
              }
            `}
            onClick={handleInvalidateKey}
          >
            Invalidate Key
          </Button>
          <ModalGenerateKey
            mainPage
            buttonStyle={`
              width: 133px;

              @media only screen and (min-width: 767px) and (max-width: 1200px) {
                width: 162px;
              }
            
              @media only screen and (max-width: 767px) {
                width: 100px;
              }
            `}
            roomNumber={roomNumber}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            setKeyPrintModalIsOpen={setKeyPrintModalIsOpen}
          />
        </div>
        <ModalKeyPrinting
          isOpen={KeyPrintModalIsOpen}
          setIsOpen={setKeyPrintModalIsOpen}
          buttonLabel={""}
          deviceId={deviceId}
        />
      </Row>
    </RoomDetailsHeaderContainer>
  );
};

export default RoomDetailsHeader;