import styled from 'styled-components';
import { Modal } from 'reactstrap';
import colors from 'constants/colors';

export const StyledModal = styled(Modal)`

  .modal-title {
    color: ${colors.red2};
  }

  ${(props) => props.additionalStyle};
`;
