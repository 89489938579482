import React from 'react';
import Input from 'components/Input';
import Card from 'components/Card';
import CardHeader from 'components/CardHeader';
import { Container } from './styles';

const PointOfContact = ({contactName, organization, setContactName, setOrganization}) => {

  return (
    <Card
      additionalStyle={`
        padding: 10px 15px;
      `}
    >
      <CardHeader>
        <div className='type'>POINT OF CONTACT</div>
      </CardHeader>
      <Container>
        <Input
          mainPage
          error={!contactName}
          title="NAME"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          additionalStyle={`
            flex-basis: 49%;
            margin-right:2%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-right: 0;
            }
          `}
        />
        <Input
          mainPage
          error={!organization}
          title="ORGANIZATION"
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
          additionalStyle={`
            flex-basis: 49%;

            @media only screen and (max-width: 767px) {
              flex-basis: 100%;
              margin-top: 25px;
            }
          `}
        />
      </Container>
    </Card>
  );
};

export default PointOfContact;
