import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePaymentForm } from 'customHook/usePaymentForm';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import PaymentMethod from 'components/BookingMain/PaymentMethod';
import GroupBookingSetUp from 'components/BookingMain/GroupBookingSetUp';
import GuestInformation from './GuestInformation';
import GeneralGroupNotesLocal from 'screens/CreateBooking/GeneralGroupNotesLocal';
import MetaInformation from 'components/BookingMain/MetaInformation';
import ModalBookingNotComplete from 'screens/ModalBookingNotComplete';
import {
  CREATE_GROUP_BOOKING,
  CREATE_GROUP_NOTE,
  UPDATE_GROUP_BY_GROUP_ID,
  GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE
} from 'graphql/service';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import { RESERVATION } from 'constants/ratePlanId';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const CreateBooking = () => {

  const history = useHistory()

  const userId = localStorage.getItem('userId')
  const fullName = localStorage.getItem('fullName')
  const [isOpen, setIsOpen] = useState(false)

  //PaymentMethod
  const paymentInfo = useSelector(state => state.paymentInfoReducer.paymentInfo)
  const [creditCardType, setCreditCardType] = useState('')
  const { isValidatePaymentInfo } = usePaymentForm(creditCardType)

  //GroupBookingSetUp
  const [groupName, setGroupName] = useState('')
  const [checkInDate, setCheckInDate] = useState(moment().hour(15).minute(0).second(0))
  const [checkOutDate, setCheckOutDate] = useState(moment().add(1,'days').hour(11).minute(0).second(0))
  const [roomQuantity, setRoomQuantity] = useState(1)
  const [roomTypeId, setRoomTypeId] = useState('')
  const [ratePlanId, setRatePlanId] = useState(RESERVATION)
  const [overrideReason, setOverrideReason] = useState('')
  const [price, setPrice] = useState('')

  const [
    getAvailableRoomsByRoomTypeIdAndDateRange,
    { data: roomsData, loading, error }
  ] = useLazyQuery(GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE)

  //GuestInformation
  const [customerInfo, setCustomerInfo] = useState({})
  const [customerInfoError, setCustomerInfoError] = useState({})

  const [createGroupNote] = useMutation(CREATE_GROUP_NOTE)
  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)
  const [createGroupBooking, { data: createBookingData }] = useMutation(CREATE_GROUP_BOOKING, {
    onCompleted: (createBookingData) => {
      if(createBookingData) {
        const groupId = createBookingData.createGroupBooking?.[0]?.tenantGroup?.group?.groupId
        const groupNotes = JSON.parse(localStorage.getItem('group_notes') || '[]')
        if(groupNotes.length) {
          groupNotes.forEach((note, index)=>{
            if(index!==groupNotes.length-1) {
              createGroupNote({
                variables: {
                  input: {
                    groupId,
                    notes: note.notes,
                    createdBy: note.createdBy,
                  }
                }
              })
            } else {
              createGroupNote({
                variables: {
                  input: {
                    groupId: groupId,
                    notes: note.notes,
                    createdBy: note.createdBy,
                  }
                },
                onCompleted: () => {
                  localStorage.removeItem('group_notes')
                  updateGroupByGroupId({
                    variables: {
                      groupId: groupId,
                      input: {
                        name: groupName
                      }
                    },
                    onCompleted: ()=>{
                      history.push(`/booking-group/${groupId}`)
                    },
                    onError: (error) => {
                      let content = error.message
                      if(error.message.includes('Validation')) {
                          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                      }
                      
                      message.error({
                        content,
                        duration: getMessageTimeOut(content),
                        onClick: () => {
                          message.destroy()
                        }
                      })
                    }
                  })
                },
                onError: (error) => {
                  let content = error.message
                  if(error.message.includes('Validation')) {
                      content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
                  }
                  
                  message.error({
                    content,
                    duration: getMessageTimeOut(content),
                    onClick: () => {
                      message.destroy()
                    }
                  })
                }
              })
            }
          })
        } else {
          history.push(`/booking-group/${groupId}`)
        }
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  const handleSaveChanges = () => {

    let result = true
    if (!customerInfo.billingAddress) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, billingAddressError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, billingAddressError: false}))
    }

    if (!customerInfo.fullName) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, fullNameError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, fullNameError: false}))
    }

    if (!customerInfo.email) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, emailError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, emailError: false}))
    }

    if (!customerInfo.phoneNumber) {
      result = false;
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, phoneNumberError: true}))
    } else {
      setCustomerInfoError((customerInfoError)=>({...customerInfoError, phoneNumberError: false}))
    }

    if(result && isValidatePaymentInfo()) {

      const bookings = []
      for (let i = 0; i < roomQuantity; i++) {
        bookings.push({
          checkInDate: checkInDate,
          checkOutDate: checkOutDate,
          roomTypeId: roomTypeId,
          isPickupRequested: false,
          isPrimaryTenant: false
        })
      }

      bookings[0].fullName = customerInfo.fullName
      bookings[0].email = customerInfo.email
      bookings[0].phoneNumber = customerInfo.phoneNumber
      bookings[0].address = customerInfo.billingAddress
      bookings[0].ratePlanId = ratePlanId
      bookings[0].isPrimaryTenant = true
      bookings[0].billingInfo = {
        creditCardNumber: paymentInfo.creditCardNumber.replace(/\s/g, ''),
        creditCardExpirationDate: paymentInfo.cardExpirationDate.replace(/[\s/]/g, ''),
        creditCardCVV: paymentInfo.cardCVV,
        creditCardHolderName: paymentInfo.cardHolderName,
        billingAddress: paymentInfo.billingAddress,
      }
    
      createGroupBooking({
        variables: {
          input: bookings
        }
      })

    } else {
      setIsOpen(true)
    }
  }

  return (
    <>
      <Header
        subHeader={
          <>
            <div className='title'>
              Bookings {'>'} Create Group Booking
            </div>
            <div className='buttonContainer'>
              <div>
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalStyle={`
                    width: 122px;
                    margin-left: 14px;
                  `}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </>
        }
      />
      <ModalBookingNotComplete
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Content>
        <ContentColumn>
          <Container>
            <GroupBookingSetUp
              groupName={groupName}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              roomQuantity={roomQuantity}
              roomTypeId={roomTypeId}
              ratePlanId={ratePlanId}
              price={price}
              setGroupName={setGroupName}
              setCheckInDate={setCheckInDate}
              setCheckOutDate={setCheckOutDate}
              setRoomQuantity={setRoomQuantity}
              setRoomTypeId={setRoomTypeId}
              setRatePlanId={setRatePlanId}
              setOverrideReason={setOverrideReason}
              setPrice={setPrice}
            />
          </Container>
          <Container>
            <PaymentMethod
              creditCardType={creditCardType}
              setCreditCardType={setCreditCardType}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          <Container>
            <GuestInformation
              customerInfo={customerInfo}
              setCustomerInfo={setCustomerInfo}
              customerInfoError={customerInfoError}
              setCustomerInfoError={setCustomerInfoError}
            />
          </Container>
          <Container>
            <GeneralGroupNotesLocal userId={userId} fullName={fullName}/>
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  )
}

export default CreateBooking;