const colors = {
  black1: '#292929',
  black2: '#3C3C3C',
  black3: '#0F0F0F',
  black4: '#262626',
  black5: '#000000',
  black6: '#414141',
  black7: '#070707',
  black8: '#151515',
  black9: '#00141A',
  black10: '#101010',
  grey1: '#434343',
  grey2: '#1F1F1F',
  grey3: '#171717',
  grey4: '#282C2E',
  grey5: '#C3C3C3',
  grey6: '#393F41',
  grey7: '#8B8B8B',
  grey8: '#535353',
  grey9: '#707070',
  grey10: '#383838',
  grey11: '#5A5A5A',
  grey12: '#7B7B7B',
  grey13: '#6E6E6E',
  grey14: '#272727',
  grey15: '#919191',
  grey16: '#656565',
  grey17: '#1E2124',
  grey18: '#CECECE',
  grey19: '#8E8E8E',
  grey20: '#333333',
  grey21: '#4D4D4E',
  grey22: '#838383',
  grey23: '#313131',
  grey24: '#737373',
  grey25: '#B2B2B2',
  grey26: '#7E7E7E',
  grey27: '#989898',
  grey28: '#1A1A1A',
  grey29: '#8D8D8D',
  red1: '#FD0027',
  red2: '#FF4553',
  red3: '#E54860',
  red4: '#F42929',
  blue1: '#07B4E8',
  blue2: '#0CCFE8',
  blue3: '#3DA3E3',
  blue4: '#2B8CED',
  blue5: '#00C2E5',
  blue6: '#2467FE',
  blue7: '#00FFC4',
  blue8:'#21F8D7',
  white1: '#FFFFFF',
  white2: '#F8F9FA',
  white3: '#F6F6F6',
  green1: '#05CB24',
  green2: '#00DD4D',
  green3: '#62D08C',
  green4: '#248132',
  green5: '#50B25F',
  green6: '#00FF4E',
  green7: '#1BEB64',
  green8: '#0E983E',
  purple1: '#4624FF',
  pink1: '#DA45FF',
  pink2: '#FF4553',
  orange1: '#F47010',
  orange2: '#E5AF48',
  orange3: '#F4BB00',
  orange4: '#FFEC00',
  brown: '#553714',
  brown2: '#D58A32',
  brown3: '#260F0F'
};

export default colors;
