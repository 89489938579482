import styled from 'styled-components';
import colors from 'constants/colors';

export const Head = styled.span`
  position: relative;
  top: -27px;
  font-size: 22px;
  font-weight: 600;
  color: ${colors.white1};
`;

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: ${colors.grey5};
  margin-bottom: 25px;
`;

export const Title = styled.span`
  color: ${colors.white1};
  font-size: 16px;
  font-weight: bold;
`;

export const Link = styled.span`
  cursor: pointer;
  color: ${colors.blue5};
  font-size: 16px;
  margin-left: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};

    span {
      font-weight: 300;
    }
  }
`;

export const Footer = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 12px;

  .select {
      height: 49px;
      background-position: 90% 50%;
      background-size: 15px 15px;
  }
`;
