import styled from 'styled-components';
import colors from 'constants/colors';

export const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-top: 15px;
    margin-bottom: 10px;
    color: ${colors.white1};

    select, input {
      height: 49px;
      background-position: 98% 50%;
      background-size: 15px 15px;
    }

    span {
      font-weight: 300;
    }
  }
`;

export const Footer = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
`;
