import styled from 'styled-components';
import colors from 'constants/colors';

export const Button = styled.button`
  border: 0;
  cursor: pointer;
  padding: 11px auto;
  height: 36px;
  background: ${colors.blue1};
  color: ${colors.black5};
  font-weight: ${(props) => (props.mainPage ? 'bold' : '600')};
  font-size: ${(props) => (props.mainPage ? '14px' : '18px')};
  
  :focus {
    outline: none;
  }
  ${({hidden}) => hidden? `display: none;`: null};
  ${({complete}) => complete? `
    border-radius:20px;   
    background:${colors.green1};
  `: null};
  ${({danger}) => danger? `background:${colors.red2}`: null};
  ${({success}) => success? `background:${colors.green1}`: null};
  ${({secondary}) => secondary? `background:${colors.grey1}`: null};

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    height: 45px;
    font-size: ${(props) => (props.mainPage ? '17px' : '18px')};
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }

  @media only screen and (max-width: 767px) {
    height: 38px;
    font-size: ${(props) => (props.mainPage ? '13px' : '18px')};
    ${({ desktop }) => (desktop ? `display: none;` : null)};
  }

  ${({ additionalStyle }) => additionalStyle};
`;
