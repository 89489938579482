
export const RESET_REDUX = 'reset_redux'

export const SET_USER = 'set_user'

export const SET_ROOM = 'set_room'

export const SET_GUEST = 'set_guest'

export const CLEAN_SELECTION = 'clean_selection'

export const CLEAN_GUEST = 'clean_guest'

export const SET_CUSTOMER_INFO = 'set_customer_info'

export const SET_SECONDARY_CUSTOMER_INFO = 'set_secondary_customer_info'

export const CLEAN_SECONDARY_CUSTOMER_INFO = 'clean_secondary_customer_info'

export const SET_STAFF_INFO = 'set_staff_info'

export const SET_PAYMENT_INFO = 'set_payment_info'

export const CLEAN_PAYMENT_INFO = 'clean_payment_info'

export const SET_PICKED_DATE = 'set_picked_date'