import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import ModalCheckOut from 'screens/ModalCheckOut';
import ModalGroupCancel from 'screens/ModalGroupCancel';
import ModalAddExistingToGroup from 'screens/ModalAddExistingToGroup';
import CreditCards from 'components/BookingMain/CreditCards';
import AddNewCreditCard from 'components/BookingMain/AddNewCreditCard';
import GuestInformation from './GuestInformation';
import GeneralGroupNotes from './GeneralGroupNotes';
import MetaInformation from 'components/BookingMain/MetaInformation';
import PointOfContact from 'components/BookingMain/PointOfContact';
import GroupTotal from 'components/BookingMain/GroupTotal';
import { useQuery, useMutation } from '@apollo/client';
import {
  UPDATE_BOOKING_BY_BOOKING_ID,
  UPDATE_GROUP_BY_GROUP_ID,
  UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID,
  GET_GROUP_DETAILS_BY_GROUP_ID
} from 'graphql/service';
import {
  Content,
  ContentColumn,
  Container,
} from 'components/BookingMain/styles';
import moment from 'moment';
import { message } from 'antd';
import { getMessageTimeOut } from 'utils/util';

const BookingGroup = () => {

  const groupId = useParams().groupId

  //CreditCards
  const [paymentCards, setPaymentCards] = useState([])
  const [tenantId, setTenantId] = useState('')

  //GuestInformation
  const [guestInfoObj, setGuestInfoObj] = useState({})
  const [overrideReason, setOverrideReason] = useState('')
  //Two dimensional array of bills
  const [billsArray, setBillsArray] = useState([])
  const [updateBookingByBooingId] = useMutation(UPDATE_BOOKING_BY_BOOKING_ID)
  const [updateRoomBooking, {error: updateRoomBookingError}] = useMutation(UPDATE_BOOKING_ROOM_BY_BOOKING_ID_AND_ROOM_ID)

  //PointOfContact
  const [contactName, setContactName] = useState('')
  const [organization, setOrganization] = useState('')
  const [updateGroupByGroupId] = useMutation(UPDATE_GROUP_BY_GROUP_ID)

  //GeneralGroupNotes
  const [groupNotes, setGroupNotes] = useState([])
  const [groupName, setGroupName] = useState([])

  const { data: groupData, loading, error, refetch } = useQuery(GET_GROUP_DETAILS_BY_GROUP_ID, {
    variables: { groupId: groupId },
    onCompleted: (groupData) => {
      if(groupData) {
        const newObj = {}
        let newBillsArray = []
        const { name, bookings, groupNotes, tenants } = groupData.groupByID
        setGroupNotes(groupNotes)
        setGroupName(name)
        const primaryTenant = tenants.find((tenant) => tenant.isPrimaryTenant === true)
        setTenantId(primaryTenant?.tenant?.tenantId || '')
        setPaymentCards(primaryTenant?.tenant?.paymentCards || [])
        bookings.forEach((booking) => {
          const {
            bookingId,
            isDeleted,
            checkInDate,
            checkOutDate,
            roomBookings,
            tenantGroup,
            bills
          } = booking  
          if(!isDeleted) {
            newBillsArray.push(bills)
            const {
              //ratePlanId,
              pricePerNight,
              room: {
                roomId,
                roomNumber,
                roomType,
              }
            } = roomBookings[0]
            const guestInfo = {
              bookingId: bookingId,
              guestName: tenantGroup?.tenant?.user?.fullName,
              isPrimaryTenant: tenantGroup?.isPrimaryTenant,
              checkInDate: checkInDate,
              checkOutDate: checkOutDate,
              price: pricePerNight,
              //ratePlanId: ratePlanId,
              roomNumber: roomNumber,
              roomId: roomId,
              roomType: roomType,
              roomChanged: false,
              dateTimeChanged: false
            }    
            newObj[bookingId] = guestInfo
          }
        })
        setGuestInfoObj(newObj)
        setBillsArray(newBillsArray)
      }
    },
    onError: (error) => {
      let content = error.message
      if(error.message.includes('Validation')) {
          content = JSON.stringify(error.graphQLErrors[0]?.extensions?.exception)
      }
      
      message.error({
        content,
        duration: getMessageTimeOut(content),
        onClick: () => {
          message.destroy()
        }
      })
    }
  })

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const guestInformationArray = []
  Object.keys(guestInfoObj).forEach((bookingId)=> {

    const setGuestInfo = (guestInfo)=>{
      const newObj = {...guestInfoObj}
      newObj[bookingId] = guestInfo
      setGuestInfoObj(newObj)
    }
    guestInformationArray.push(      
      <Container
        key={bookingId}
      >
        <GuestInformation
          guestInfo={guestInfoObj[bookingId]}
          groupId={groupId}
          setOverrideReason={setOverrideReason}
          setGuestInfo={setGuestInfo}
        />
      </Container>      
    )
  })

  const handleAddNewToGroup = () =>{
    // createGroupBooking({
    //   variables: {
    //     input: {
    //       booking: {
    //         groupId: groupId,
    //         checkInDate: moment().toDate(),
    //         checkOutDate: moment().add(1, 'days').toDate(),
    //       }
    //     }
    //   },
    // })
  }

  const handleSaveChanges = () => {
    
    // updateGroupByGroupId({
    //   variables: {
    //     groupId: groupId,
    //     input: {
    //       //FIXME: point of contact
    //     }
    //   }
    // })

    Object.keys(guestInfoObj).forEach((bookingId) => {
      const guestInfo = guestInfoObj[bookingId]
      const { checkInDate, checkOutDate, roomId, roomChanged, dateTimeChanged } = guestInfo
      
      if(roomChanged) {
        updateRoomBooking({
          variables: {
            input: {
              roomId,
              bookingId
            },
          },
        })
      }

      if(dateTimeChanged) {
        if(moment(checkInDate) >= moment().toDate()) {
          updateBookingByBooingId({
            variables: {
              bookingId,
              input: {
                checkInDate,
                checkOutDate,
              }
            },
          })
        } else if(moment(checkOutDate) >= moment().toDate()) {
          updateBookingByBooingId({
            variables: {
              bookingId,
              input: {
                checkOutDate,
              }
            },
          })
        } 
      }
    })
  }

  return (
    <>
      <Header
        subHeader={
          <>
            <div className='title'>
              Bookings {'>'} {groupName}
            </div>
            <div className='buttonContainer'>
              <div>
                <ModalCheckOut
                  buttonStyle={`
                    width: 113px;

                    @media only screen and (max-width: 767px) {
                      width: 85px;
                    }
                  `}
                  groupId={groupId}
                />
                <Button
                  mainPage
                  onClick={() => { }}
                  additionalStyle={`
                    width: 113px;
                    margin-left: 14px;
                    
                    @media only screen and (max-width: 767px) {
                      width: 85px;
                      margin-left: 5px;
                    }
                  `}
                >
                  Check In
                </Button>
                <ModalAddExistingToGroup
                  buttonStyle={`
                    width: 175px;
                    margin-left: 14px;

                    @media only screen and (max-width: 767px) {
                      width: 135px;
                      margin-left: 5px;
                    }
                  `}
                  groupId={groupId}
                  refetch={refetch}
                />
              </div>
              <div>
                <Button
                  mainPage
                  onClick={handleAddNewToGroup}
                  additionalStyle={`
                    width: 154px;
                    margin-left: 14px;

                    @media only screen and (max-width: 767px) {
                      width: 115px;
                      margin-left: 5px;
                    }
                  `}
                >
                  Add new to group
                </Button>
                <ModalGroupCancel
                  groupId={groupId}
                  danger
                  buttonStyle={`
                    width: 138px;
                    margin-left: 14px;

                    @media only screen and (max-width: 767px) {
                      width: 100px;
                      margin-left: 5px;
                    }
                  `}
                />
                <Button
                  mainPage
                  success
                  onClick={handleSaveChanges}
                  additionalStyle={`
                    width: 122px;
                    margin-left: 14px;

                    @media only screen and (max-width: 767px) {
                      width: 90px;
                      margin-left: 5px;
                    }
                  `}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </>
        }
      />
      <Content>
        <ContentColumn>
          <Container>
            <PointOfContact
              contactName={contactName}
              setContactName={setContactName}
              organization={organization}
              setOrganization={setOrganization}
            />
          </Container>
          <Container>
            <CreditCards paymentCards={paymentCards} refetch={refetch}/>
            <AddNewCreditCard tenantId={tenantId}/>
          </Container>
          <Container>
            <GroupTotal
              billsArray={billsArray}
              refetch={refetch}
              groupId={groupId}
            />
          </Container>
        </ContentColumn>
        <ContentColumn>
          {
            guestInformationArray
          }
          <Container>
            <GeneralGroupNotes
              notes={groupNotes}
              groupId={groupId}
              headerString={'GENERAL GROUP NOTES'}
              refetch={()=>{
                refetch().then((groupData) => {
                  setGroupNotes(groupData.data.groupByID?.groupNotes)
                })
              }}
            />
          </Container>
          <Container>
            <MetaInformation
              created={Date.now()}
              origin={"Booking.com"}
              confirmationNumber={"#########"}
              channel={"Lorem Ipsum"}
              campaignTag={"Christmas Promo 2020"}
            />
          </Container>
        </ContentColumn>
      </Content>
      <Footer />
    </>
  )
}

export default BookingGroup;