import React, { useState } from 'react';
import MainModal from 'components/ModalMain';
import Button from 'components/Button';
import { CREATE_BOOKING_CHECK_OUT } from 'graphql/service';
import { useMutation } from '@apollo/client';
import colors from 'constants/colors';
import { ReactComponent as CheckOut } from 'assets/svg/CheckOut.svg';
import { Container } from './styles';

const ModalCheckOut = ({buttonStyle, groupId}) => {

  const [isOpen, setIsOpen] = useState(false)

  const handleCheckOut = () => {
    setIsOpen(false)
  }

  return (
    <MainModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="modal-success"
      buttonLabel="Check Out"
      buttonStyle={buttonStyle}
      additionalStyle={`
        max-width: 516px;
        .modal-content{
          height: 274px;
          .close {
            z-index: 200;
          }   
          .modal-body{
            position: relative;
            top: -5px;
            height: 100%;
          }
        }
        background: ${colors.grey3};
        top: 179px;
      `}
    >
      <Container>
        <CheckOut />
        <div>Are you sure you want to check out?</div>
        <Button
          success
          additionalStyle={`
            height: 53px;
            width: 130px;
          `}
          onClick={handleCheckOut}
        >
          Check Out
        </Button>
      </Container>
    </MainModal>
  );
}

export default ModalCheckOut;
